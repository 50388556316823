import React from "react";
import "style/index.css";

// components
import {
  ExperienceWrapper,
  ExperienceBackgroundImage,
  ExperienceHeaderWrapper,
  ExperienceTilesWrapper,
  ExperienceTile,
  // ExperienceArrowWrapper,
  ExperienceIconWrapper,
} from "components/styledsections/ExperienceStyledSections";
import { Heading3, Heading5, Body1, Body2 } from "components/TextStyles";
// import Link from "components/Elements/ScrollToTopLink";

// assets
import LearnBulb from "assets/svg/icons/experience/LearnBulb";
import HealthHeart from "assets/svg/icons/experience/HealthHeart";
import ExploreCompass from "assets/svg/icons/experience/ExploreCompass";
import SocialSunny from "assets/svg/icons/experience/SocialSunny";
import WorkConstruct from "assets/svg/icons/experience/WorkConstruct";
import WealthKey from "assets/svg/icons/experience/WealthKey";
// import ArrowRight from "assets/svg/ArrowRight";
import Background from "assets/img/backgrounds/dandelionEarth.png";

export default function LowerTextSection() {
  return (
    <>
      <ExperienceWrapper>
        <ExperienceHeaderWrapper marginTop="180px">
          <Heading3>Your Experience</Heading3>
          <Body1 textAlign="center">
            ELLA is your friendly and intelligent AI assistant who will help you
            to embrace change, find new opportunities and overcome challenges.
            She will guide you on many of the most important conversations and
            decisions for you to thrive over 45.
          </Body1>
        </ExperienceHeaderWrapper>
        <ExperienceTilesWrapper>
          <ExperienceTile borderColor="var(--Tea-Green, #C2E9C2);">
            <ExperienceIconWrapper>
              <LearnBulb color="#C2E9C2" />
            </ExperienceIconWrapper>
            <Heading5
              fontColor="var(--Tea-Green, #C2E9C2);"
              maxFontSize="2.1rem"
            >
              Learn
            </Heading5>
            <Body2 fontColor="var(--Lite-Grey, #CCC);">
              Always wanted to learn a language, a new technology or do
              something creative? We can help you find the best courses to
              follow or revisit your dreams and passions.
            </Body2>
            {/* <Link to="/">
              <ExperienceArrowWrapper>
                <ArrowRight width="20px" stroke="var(--Tea-Green, #C2E9C2)" />
              </ExperienceArrowWrapper>
            </Link> */}
          </ExperienceTile>
          <ExperienceTile borderColor="var(--Cream, #F0D8A7)">
            <ExperienceIconWrapper>
              <WorkConstruct color="#F0D8A7" />
            </ExperienceIconWrapper>
            <Heading5 fontColor="var(--Cream, #F0D8A7)" maxFontSize="2.1rem">
              Work
            </Heading5>
            <Body2 fontColor="var(--Lite-Grey, #CCC);">
              Get access to part-time, temp, just-in-time gigs or volunteering
              opportunities. We will match you with an age inclusive employer
              and a community who value your experience.
            </Body2>
            {/* <Link to="/">
              <ExperienceArrowWrapper>
                <ArrowRight width="20px" stroke="var(--Cream, #F0D8A7)" />
              </ExperienceArrowWrapper>
            </Link> */}
          </ExperienceTile>
          <ExperienceTile borderColor="var(--Aqua, #4BFFDF)">
            <ExperienceIconWrapper>
              <HealthHeart color="#4BFFDF" />
            </ExperienceIconWrapper>
            <Heading5 fontColor="var(--Aqua, #4BFFDF)" maxFontSize="2.1rem">
              Health
            </Heading5>
            <Body2 fontColor="var(--Lite-Grey, #CCC);">
              Your health and wellbeing is important. Grey Matters empower you
              to actively take control of your wellbeing so you can perform at
              your best.
            </Body2>
            {/* <Link to="/">
              <ExperienceArrowWrapper>
                <ArrowRight width="20px" stroke="var(--Aqua, #4BFFDF)" />
              </ExperienceArrowWrapper>
            </Link> */}
          </ExperienceTile>
          <ExperienceTile borderColor="var(--Silver, #999999)">
            <ExperienceIconWrapper>
              <WealthKey color="#999999" />
            </ExperienceIconWrapper>
            <Heading5 fontColor="var(--Silver, #999999)" maxFontSize="2.1rem">
              Wealth
            </Heading5>
            <Body2 fontColor="var(--Lite-Grey, #CCC);">
              Need help with financial, legal and insurance decisions? We are
              here to help provide the stability and security needed to turn
              your aspirations into sustainable achievements.
            </Body2>
            {/* <Link to="/">
              <ExperienceArrowWrapper>
                <ArrowRight width="20px" stroke="var(--Silver, #999999)" />
              </ExperienceArrowWrapper>
            </Link> */}
          </ExperienceTile>
          <ExperienceTile borderColor="var(--Light-Cream, #FEF4ED);">
            <ExperienceIconWrapper>
              <ExploreCompass color="#FEF4ED" />
            </ExperienceIconWrapper>
            <Heading5
              fontColor="var(--Light-Cream, #FEF4ED);"
              maxFontSize="2.1rem"
            >
              Explore
            </Heading5>
            <Body2 fontColor="var(--Lite-Grey, #CCC);">
              Let us help you plan your next trip, discover a new culture, or
              experience something you've always wanted to but never had the
              time to. There are more than seven wonders.
            </Body2>
            {/* <Link to="/">
              <ExperienceArrowWrapper>
                <ArrowRight width="20px" stroke="var(--Light-Cream, #FEF4ED)" />
              </ExperienceArrowWrapper>
            </Link> */}
          </ExperienceTile>
          <ExperienceTile borderColor="var(--Lavender, #A6BCFF)">
            <ExperienceIconWrapper>
              <SocialSunny color="#A6BCFF" />
            </ExperienceIconWrapper>
            <Heading5 fontColor="var(--Lavender, #A6BCFF)" maxFontSize="2.1rem">
              Connect
            </Heading5>
            <Body2 fontColor="var(--Lite-Grey, #CCC);">
              Join one of our many clubs and create or join your own social
              circles; invite friends or make new ones to share opportunities
              and challenges with.
            </Body2>
            {/* <Link to="/">
              <ExperienceArrowWrapper>
                <ArrowRight width="20px" stroke="var(--Lavender, #A6BCFF)" />
              </ExperienceArrowWrapper>
            </Link> */}
          </ExperienceTile>
        </ExperienceTilesWrapper>
        <ExperienceBackgroundImage backgroundImage={Background} />
      </ExperienceWrapper>
    </>
  );
}
