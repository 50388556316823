import React from "react";
import styled from "styled-components";

// Components
import { Body2, Body3 } from "components/TextStyles";
import { Link } from "react-scroll";
import ScrollLink from "components/elements/ScrollToTopLink";

// Assets
import GMLogoWhite from "assets/svg/gmlogos/GMLogoSmallWhite";
import LinkedInIcon from "assets/svg/icons/socialmediaicons/LinkedInIcon";

export default function Footer() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <Row sub560Wrap="column">
        <LeftSide>
          {" "}
          <Link
            className="flexCenter animate pointer"
            to="home"
            smooth={true}
            offset={-80}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <GMLogoWhite />
              <h1
                className="font15 extraBold whiteColor"
                style={{ marginLeft: "15px" }}
              >
                Grey Matters<sup>®</sup>
              </h1>
            </div>
          </Link>
        </LeftSide>
      </Row>
      <Row sub560Wrap="column">
        <LeftSide>
          <Row sub760Wrap="column">
            <Col>
              <Body2 whiteSpace="nowrap">Site</Body2>
              <Row>
                <Col>
                  <ScrollLink to="/about">
                    <Body3 fontColor="#aaa" whiteSpace="nowrap">
                      About
                    </Body3>
                  </ScrollLink>
                  <ScrollLink to="/greytasker">
                    <Body3 fontColor="#aaa" whiteSpace="nowrap">
                      GreyTasker
                    </Body3>
                  </ScrollLink>
                  <ScrollLink to="/greyacademy">
                    <Body3 fontColor="#aaa" whiteSpace="nowrap">
                      GreyAcademy
                    </Body3>
                  </ScrollLink>
                  <ScrollLink to="/insights/manifesto">
                    <Body3 fontColor="#aaa" whiteSpace="nowrap">
                      Manifesto
                    </Body3>
                  </ScrollLink>
                  <ScrollLink to="/contact">
                    <Body3 fontColor="#aaa" whiteSpace="nowrap">
                      Contact Us
                    </Body3>
                  </ScrollLink>
                </Col>
                <Col>
                  <ScrollLink to="/organisations/corporations">
                    <Body3 fontColor="#aaa" whiteSpace="nowrap">
                      Corporates
                    </Body3>
                  </ScrollLink>
                  <ScrollLink to="/organisations/clubs">
                    <Body3 fontColor="#aaa" whiteSpace="nowrap">
                      Clubs
                    </Body3>
                  </ScrollLink>
                  <ScrollLink to="/organisations/individuals">
                    <Body3 fontColor="#aaa" whiteSpace="nowrap">
                      Individuals
                    </Body3>
                  </ScrollLink>
                  <ScrollLink to="/organisations/public-sector">
                    <Body3 fontColor="#aaa" whiteSpace="nowrap">
                      Public Sector
                    </Body3>
                  </ScrollLink>
                </Col>
                <Col>
                  <ScrollLink to="/insights/media">
                    <Body3 fontColor="#aaa" whiteSpace="nowrap">
                      Media
                    </Body3>
                  </ScrollLink>
                  <ScrollLink to="/insights/research">
                    <Body3 fontColor="#aaa" whiteSpace="nowrap">
                      Research
                    </Body3>
                  </ScrollLink>
                </Col>
              </Row>
            </Col>
            <Col>
              <Body2 whiteSpace="nowrap">Legal</Body2>
              <Row>
                <Col>
                  <a href="/privacy.html">
                    <Body3 fontColor="#aaa" whiteSpace="nowrap">
                      Privacy Policy
                    </Body3>
                  </a>
                  {/* <Body3 whiteSpace="nowrap">Terms and Conditions</Body3> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </LeftSide>
        <RightSide>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              maxWidth: "300px",
            }}
          >
            <Body2 textAlignMobile="left" textAlign="left">
              Acknowledgement of Country
            </Body2>
            <Body3 textAlignMobile="left" fontColor="#aaa" textAlign="left">
              We acknowledge the Traditional Owners of the land where we work
              and live. We pay our respects to their Elders, past, present and
              emerging.
            </Body3>
          </div>
        </RightSide>
      </Row>
      <Row sub560Wrap="column">
        <LeftSide>
          <a href="https://www.linkedin.com/company/grey-matters-ai/">
            <LinkedInIcon />
          </a>
        </LeftSide>
        <RightSide>
          <Body3 fontColor="#ccc" whiteSpace="nowrap">
            © {getCurrentYear()} - Digital Natives Advisory All Rights Reserved
          </Body3>
        </RightSide>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #1d1f27;

  padding: 30px 10vw;

  width: 100%;

  @media (max-width: 560px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const LeftSide = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
`;

const RightSide = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 16px;

  @media (max-width: 560px) {
    align-items: flex-start;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  padding: 3px 0;

  @media (max-width: 760px) {
    flex-direction: ${(props) => props.sub760Wrap || "row"};
  }

  @media (max-width: 560px) {
    padding: 7px 0;
    flex-direction: ${(props) => props.sub560Wrap || "row"};
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding: 3px clamp(25px, 3.5vw, 40px) 3px 0;
  gap: 2px;
`;
