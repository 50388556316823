import React from "react";
import "style/index.css";
import styled from "styled-components";

// components
import { Body1, Heading3 } from "components/TextStyles";
import Button from "components/styledelements/Button";
import GMLogo from "assets/svg/gmlogos/GMLogoSmallWhite";

export default function Manifesto() {
  return (
    <>
      <Wrapper>
        <GMLogo width="48px" stroke="black" />
        <Heading3 fontColor="black">Manifesto</Heading3>
        <Body1 fontColor="black" textAlign="center" marginBottom="20px">
          Grey Matters™ stands as a rallying cry for a workforce where age is
          not a limitation but a beacon of strength. Recognising that ageing is
          a significant social issue, we assert our collective responsibility to
          solve it. Together, let's champion an inclusive future that harnesses
          the full spectrum of human experience and potential, contributing to a
          sustainable and equitable world.
        </Body1>
        <Button
          to="/insights/manifesto"
          text="Full Manifesto"
          arrow="right"
          color="black"
        />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  padding: 72px 10vw;
  background-color: #a6bcff;
  @media (max-width: 560px) {
    padding: 48px 5vw;
  }
`;
