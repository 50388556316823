import styled from "styled-components";
import React from "react";
// import Link from "components/Elements/ScrollToTopLink";

// Components
import {
  ExperienceWrapper,
  ExperienceTile,
  ExperienceTilesWrapper,
  ExperienceHeaderWrapper,
  ExperienceIconWrapper,
  // ExperienceArrowWrapper,
} from "components/styledsections/ExperienceStyledSections";
import { Heading2, Heading5, Body1, Body2 } from "components/TextStyles";

// Assets
import Background from "assets/img/backgrounds/dandelionEarth.png";
import Dandelion from "assets/img/gmlogos/filledLogo.png";
// import ArrowRight from "assets/svg/ArrowRight";

import LearnBulb from "assets/svg/icons/experience/LearnBulb";
import HealthHeart from "assets/svg/icons/experience/HealthHeart";
import ExploreCompass from "assets/svg/icons/experience/ExploreCompass";
import SocialSunny from "assets/svg/icons/experience/SocialSunny";
import WorkConstruct from "assets/svg/icons/experience/WorkConstruct";
import WealthKey from "assets/svg/icons/experience/WealthKey";

export default function Experience() {
  return (
    <ExperienceWrapper>
      <MainDandelionWrapper>
        <img src={Dandelion} alt="dandelion"></img>
      </MainDandelionWrapper>
      <ExperienceHeaderWrapper marginTop>
        <Heading2>Member Experience</Heading2>
        <Body1 mobileTextAlign="left" textAlign="center">
          ELLA is a state-of-the-art AI companion, coach, and guide to help
          members find fun, fulfilment, and balance in their life. Whether
          transitioning into a new career, learning something new, finding a
          tribe, understanding finances better or living healthier longer - ELLA
          inspires and guides our members every step of the way.
        </Body1>
      </ExperienceHeaderWrapper>
      <ExperienceTilesWrapper>
        <ExperienceTile borderColor="var(--Tea-Green, #C2E9C2);">
          <ExperienceIconWrapper>
            <LearnBulb color="#C2E9C2" />
          </ExperienceIconWrapper>
          <Heading5 fontColor="var(--Tea-Green, #C2E9C2);" maxFontSize="2.1rem">
            Learn
          </Heading5>
          <Body2 fontColor="var(--Lite-Grey, #CCC);">
            Embrace lifelong learning with personalised and curated access to
            online courses, micro-credentials and certifications. Continue to
            learn, grow, and challenge yourself.
          </Body2>
          {/* <Link to="/">
            <ExperienceArrowWrapper>
              <ArrowRight width="20px" stroke="var(--Tea-Green, #C2E9C2)" />
            </ExperienceArrowWrapper>
          </Link> */}
        </ExperienceTile>
        <ExperienceTile borderColor="var(--Cream, #F0D8A7)">
          <ExperienceIconWrapper>
            <WorkConstruct color="#F0D8A7" />
          </ExperienceIconWrapper>
          <Heading5 fontColor="var(--Cream, #F0D8A7)" maxFontSize="2.1rem">
            Work
          </Heading5>
          <Body2 fontColor="var(--Lite-Grey, #CCC);">
            We support you to re-imagine your future work possibilities. ELLA
            suggests gigs tailored to your work style, passions and preferences,
            both in traditional or more flexible work options.
          </Body2>
          {/* <Link to="/">
            <ExperienceArrowWrapper>
              <ArrowRight width="20px" stroke="var(--Cream, #F0D8A7)" />
            </ExperienceArrowWrapper>
          </Link> */}
        </ExperienceTile>
        <ExperienceTile borderColor="var(--Aqua, #4BFFDF)">
          <ExperienceIconWrapper>
            <HealthHeart color="#4BFFDF" />
          </ExperienceIconWrapper>
          <Heading5 fontColor="var(--Aqua, #4BFFDF)" maxFontSize="2.1rem">
            Health
          </Heading5>
          <Body2 fontColor="var(--Lite-Grey, #CCC);">
            Live healthier longer. Grey Matters empowers you to actively take
            control of your wellbeing so that you can live well and perform at
            your best for longer.
          </Body2>
          {/* <Link to="/">
            <ExperienceArrowWrapper>
              <ArrowRight width="20px" stroke="var(--Aqua, #4BFFDF)" />
            </ExperienceArrowWrapper>
          </Link> */}
        </ExperienceTile>
        <ExperienceTile borderColor="var(--Silver, #999999)">
          <ExperienceIconWrapper>
            <WealthKey color="#999999" />
          </ExperienceIconWrapper>
          <Heading5 fontColor="var(--Silver, #999999)" maxFontSize="2.1rem">
            Wealth
          </Heading5>
          <Body2 fontColor="var(--Lite-Grey, #CCC);">
            We help guide financial, legal and insurance decisions to provide
            the stability and security needed to freely transform your
            aspirations into tangible achievements.
          </Body2>
          {/* <Link to="/">
            <ExperienceArrowWrapper>
              <ArrowRight width="20px" stroke="var(--Silver, #999999)" />
            </ExperienceArrowWrapper>
          </Link> */}
        </ExperienceTile>
        <ExperienceTile borderColor="var(--Light-Cream, #FEF4ED);">
          <ExperienceIconWrapper>
            <ExploreCompass color="#FEF4ED" />
          </ExperienceIconWrapper>
          <Heading5
            fontColor="var(--Light-Cream, #FEF4ED);"
            maxFontSize="2.1rem"
          >
            Explore
          </Heading5>
          <Body2 fontColor="var(--Lite-Grey, #CCC);">
            Let us help you plan your next trip, discover a new culture, or
            experience something you've always wanted to but never had the time
            to. There are more than seven wonders.
          </Body2>
          {/* <Link to="/">
            <ExperienceArrowWrapper>
              <ArrowRight width="20px" stroke="var(--Light-Cream, #FEF4ED)" />
            </ExperienceArrowWrapper>
          </Link> */}
        </ExperienceTile>
        <ExperienceTile borderColor="var(--Lavender, #A6BCFF)">
          <ExperienceIconWrapper>
            <SocialSunny color="#A6BCFF" />
          </ExperienceIconWrapper>
          <Heading5 fontColor="var(--Lavender, #A6BCFF)" maxFontSize="2.1rem">
            Connect
          </Heading5>
          <Body2 fontColor="var(--Lite-Grey, #CCC);">
            Create and join social circles to stay connected, inspired and
            supported. Keep active by joining our local community clubs and
            attending exciting events.
          </Body2>
          {/* <Link to="/">
            <ExperienceArrowWrapper>
              <ArrowRight width="20px" stroke="var(--Lavender, #A6BCFF)" />
            </ExperienceArrowWrapper>
          </Link> */}
        </ExperienceTile>
      </ExperienceTilesWrapper>
      <BackgroundImage />
      <DandelionBlur1 />
      <DandelionBlur2 />
      <DandelionBlur3 />
      <DandelionBlur4 />
    </ExperienceWrapper>
  );
}

const BackgroundImage = styled.section`
  opacity: 0.7;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(
      180deg,
      #12141a 42.81%,
      rgba(18, 20, 26, 0) 60.36%
    ),
    url(${Background});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
  z-index: -2;
  overflow: hidden;
`;

const MainDandelionWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding-top: calc(40px + 10vh);
  padding-bottom: 30px;
  z-index: 1;

  img {
    width: 30vw;
  }
`;

const DandelionBlur1 = styled.img.attrs(() => ({ src: Dandelion }))`
  position: absolute;
  top: 25%;
  left: 14%;
  opacity: 0.5;
  width: 10%;
  height: auto;
  filter: blur(16px);
  transform: rotate(-30deg);
  z-index: -1;

  @media (max-width: 960px) {
    filter: blur(8px);
  }
`;

const DandelionBlur2 = styled.img.attrs(() => ({ src: Dandelion }))`
  position: absolute;
  top: 35%;
  right: 15%;
  opacity: 0.7;
  width: 15%;
  height: auto;
  filter: blur(14px);
  transform: rotate(30deg);
  z-index: -1;

  @media (max-width: 960px) {
    filter: blur(8px);
  }
`;

const DandelionBlur3 = styled.img.attrs(() => ({ src: Dandelion }))`
  position: absolute;
  top: 0%;
  right: 0%;
  opacity: 0.8;
  width: 30%;
  height: auto;
  filter: blur(25px);
  transform: rotate(45deg);
  z-index: -1;

  @media (max-width: 960px) {
    filter: blur(18px);
  }
`;

const DandelionBlur4 = styled.img.attrs(() => ({ src: Dandelion }))`
  position: absolute;
  top: 40%;
  left: 20%;
  opacity: 0.5;
  width: 15%;
  height: auto;
  filter: blur(16px);
  transform: rotate(-30deg);
  z-index: -1;

  @media (max-width: 960px) {
    filter: blur(8px);
  }
`;
