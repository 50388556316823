import React, { useEffect } from "react";
import styled from "styled-components";

// components
import { Body1, Heading2 } from "components/TextStyles";
import Modal from "./Modal";
import Button from "components/styledelements/Button";

export default function ResultPage({ result }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Modal result={result}/>
      <Wrapper>
        {result === "cv" && CreativeVisionary}
        {result === "cc" && CommunityCarer}
        {result === "ll" && LifelongLearner}
        {result === "pe" && PassionateExplorer}
        <Button to="/" text="Home" arrow="right" />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 120px;
  gap: 32px;

  @media (max-width: 860px) {
    padding 100px 80px;
  }

    @media (max-width: 560px) {
    padding 90px 40px;
  }
`;

const PassionateExplorer = (
  <>
    <Heading2 fontFamily="valizas">
      You're a{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>
        Passionate Explorer
      </span>
    </Heading2>
    <Body1>
      You are a Passionate Explorer archetype, characterised by a thirst for
      continuous learning, new experiences, and a value for freedom and
      autonomy, here's a journey map tailored to your unique traits and
      aspirations. This archetype thrives on exploration and discovery, both
      personally and professionally, often seeking variety and excitement over
      security. Your journey is about embracing new horizons, learning, and
      growth.
    </Body1>
    <Body1>
      You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Adventurous</span>
      <br></br>
      You're <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Curious</span>
      <br></br>
      You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Open-minded</span>
      <br></br>
      You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Energetic</span>
      <br></br>
      You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Spontaneous</span>
      <br></br>
    </Body1>
    <Body1 fontColor="#a6bcff">Exploration and Adventure:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Travel and Cultural Immersion:</span>{" "}
      Embrace opportunities to travel, whether locally or internationally, to
      immerse yourself in different cultures and experiences. This can broaden
      your horizons and feed your passion for discovery.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Adventure Sports:</span> Consider
      trying adventure sports or outdoor activities that challenge you
      physically and mentally, such as hiking, climbing, or kayaking.
    </Body1>
    <Body1 fontColor="#a6bcff">LifeLong Learning:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Online Courses and Workshops:</span>{" "}
      Sign up for online courses or workshops in areas that pique your interest.
      Our online learning offers a wide range of topics.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>
        Attend Seminars and Conferences:
      </span>{" "}
      Engage in seminars and conferences related to your interests. These events
      can also be excellent networking opportunities.
    </Body1>
    <Body1 fontColor="#a6bcff">Career Flexibility:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Freelance or Consulting Work:</span>{" "}
      Explore freelance or consulting opportunities that allow for flexibility
      and autonomy. These roles can enable you to work on a variety of projects
      and continuously learn new skills.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Transitional Roles:</span> Consider
      roles that allow you to transition between different fields or industries,
      leveraging your broad skill set and adaptability.
    </Body1>
    <Body1 fontColor="#a6bcff">Networking and Community:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Join Interest-Based Clubs:</span>{" "}
      Connect with clubs that align with your passions, such as hiking clubs,
      book clubs, or photography groups.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Volunteering:</span> Volunteer for
      causes you care about. This can provide a sense of purpose and
      fulfillment, alongside the opportunity to meet like-minded individuals.
    </Body1>
    <Body1 fontColor="#a6bcff">Self-Discovery:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Journaling and Reflection:</span> Keep
      a journal to reflect on your experiences, learnings, and personal growth.
      This can help in identifying patterns in your interests and guiding future
      explorations.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Mindfulness and Meditation:</span>{" "}
      Practice mindfulness and meditation to stay grounded and present,
      enhancing your capacity to enjoy new experiences fully.
    </Body1>
    <Body1 fontColor="#a6bcff">Balance and Wellbeing:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Prioritise Self-care: </span>
      Ensure you're taking care of your physical and mental health. Engage in
      activities that rejuvenate you, like yoga, reading, or spending time in
      nature.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>
        Financial Planning for Flexibility:
      </span>{" "}
      Since explorers often prioritise experiences over security, it's crucial
      to plan financially to support your lifestyle while ensuring stability.
    </Body1>
    <Body1>
      Your archetype, the{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>
        Passionate Explorer
      </span>
      , is a testament to the belief that life is a continuous journey of
      learning and discovery. By embracing the unknown with enthusiasm and an
      open mind, you can find joy and fulfillment in the myriad experiences life
      offers. Remember, the path of exploration is not just about reaching a
      destination but about savouring the journey and growing with every step
      you take. Stay curious, be adventurous, and let your passion for
      exploration lead the way to a rich and fulfilling life.
    </Body1>
  </>
);

const CreativeVisionary = (
  <>
    <Heading2 fontFamily="valizas">
      You're a{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>
        Creative Visionary
      </span>
    </Heading2>
    <Body1>
      You are a creative visionary, marked by an unyielding drive to innovate
      and transform the world according to their unique vision, this journey map
      is crafted to align with your intrinsic motivations and aspirations. As
      Creative Visionaries, you are distinguished by your risk tolerance,
      forward-thinking, and profound identification with your work, viewing it
      as an extension of your personal legacy. Your journey is about leveraging
      your creativity and vision to make a lasting impact, particularly in the
      later years of life when the opportunity for self-expression and pursuit
      of long-held dreams becomes paramount.
    </Body1>
    <Body1>
      You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Innovative</span>
      <br></br>
      You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Imaginative</span>
      <br></br>
      You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Inspirational</span>
      <br></br>
      You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Inventive</span>
      <br></br>
      You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Intuitive</span>
      <br></br>
    </Body1>
    <Body1 fontColor="#a6bcff">Creative Expression and Innovation:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Personal Projects:</span> Dedicate
      time to personal projects or start-ups that align with your vision for
      change. This could involve creating a new product, service, or initiating
      a social movement.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Artistic Endeavours:</span> Explore
      various forms of art—painting, writing, music, or digital media—to express
      your unique perspective and ideas. Art can be a powerful medium to
      communicate your vision and influence others.
    </Body1>
    <Body1 fontColor="#a6bcff">Lifelong Learning and Skill Development:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Advanced Workshops and Courses:</span>{" "}
      Engage in advanced workshops or courses in fields related to your creative
      interests or areas where you seek more control to realise your vision,
      such as technology, design thinking, or leadership.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Mentorship and Collaboration:</span>{" "}
      Seek mentorship opportunities and Collaborate with others to enhance your
      projects with diverse insights and skills.
    </Body1>
    <Body1 fontColor="#a6bcff">Work - Entrepreneurial Ventures:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Entrepreneurship:</span> Consider
      launching your own venture that embodies your vision for the future. This
      can be an avenue for profound personal and professional fulfillment, as
      well as a way to leave a lasting legacy.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Consultancy:</span> Offer your
      visionary insights as a consultant to organisations or industries aligned
      with your passions. Your experience and forward-thinking can guide others
      toward innovative solutions.
    </Body1>
    <Body1 fontColor="#a6bcff">Networking and Community Engagement:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Professional Networks:</span> Build
      and engage with a network of like-minded individuals and professionals who
      share your visionary outlook. This community can offer support, resources,
      and collaboration opportunities.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>
        Public Speaking and Presentations:
      </span>{" "}
      Share your vision and projects through public speaking engagements,
      presentations, or TED Talks. Communicating your ideas publicly can inspire
      others and attract support for your initiatives.
    </Body1>
    <Body1 fontColor="#a6bcff">Personal Reflection and Growth:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Journaling and Vision Mapping:</span>{" "}
      Maintain a journal or vision board to articulate your ideas, track your
      progress, and reflect on your journey. This practice can help clarify your
      goals and motivate you to continue pushing boundaries.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Mindfulness and Well-being:</span>{" "}
      Incorporate mindfulness practices to stay focused and balanced amidst the
      challenges of pursuing your vision. Prioritising your mental and physical
      health ensures you have the energy to continue your creative endeavours.
    </Body1>
    <Body1 fontColor="#a6bcff">Financial Planning:</Body1>
    <Body1>
      Develop a strategic approach to financial management that supports your
      creative projects and entrepreneurial ventures, ensuring you have the
      resources to bring your visions to life.
    </Body1>
    <Body1>
      As a{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>
        Creative Visionary
      </span>
      , your desire to change the world through your unique vision is a powerful
      force for innovation and transformation. Embracing your creativity, risk
      tolerance, and forward-thinking nature, especially in later years, offers
      unparalleled opportunities for self-expression and legacy building. By
      focusing on personal growth, strategic planning, and community engagement,
      you can navigate the challenges of realising your vision while maintaining
      balance and well-being. Let your passion for change guide you toward a
      fulfilling journey, marked by significant contributions to the world and a
      lasting legacy.
    </Body1>
  </>
);

const CommunityCarer = (
  <>
    <Heading2 fontFamily="valizas">
      You're a{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Community Carer</span>
    </Heading2>
    <Body1>
      You are a Community Carer, characterised by a deep-rooted sense of
      altruism and a desire to make a meaningful impact on the lives of others
      and the environment. Here's a journey map that aligns with your innate
      drive to support, nurture, and contribute to a better world. This
      archetype finds fulfillment in enhancing community well-being, prioritises
      purpose over financial gain, and often excels in professions that provide
      direct benefits to individuals and society.
    </Body1>
    <Body1>
      You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Adventurous</span>
      <br></br>
      You're <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Curious</span>
      <br></br>
      You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Open-minded</span>
      <br></br>
      You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Energetic</span>
      <br></br>
      You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Spontaneous</span>
      <br></br>
    </Body1>
    <Body1 fontColor="#a6bcff">Community Engagement:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Volunteer Work:</span> Seek out
      volunteer opportunities that align with your passions—be it environmental
      conservation, education, healthcare, or social services. Your effort can
      make a tangible difference in these areas.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Local Initiatives:</span> Get involved
      in local initiatives or start your own to address specific community
      needs. Whether it's organising a neighborhood clean-up, a food drive, or a
      community garden, your actions can foster a sense of community and
      collective care.
    </Body1>
    <Body1 fontColor="#a6bcff">Career Pathways & Transitions:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Caring Professions:</span> Consider
      jobs or gigs in healthcare, teaching, social work, or counseling, where
      you can directly contribute to improving others' lives. Your empathetic
      nature is a tremendous asset in these fields.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Non-Profit Organisations:</span>{" "}
      Explore roles within non-profit organisations whose missions resonate with
      your values. These positions can offer fulfilling ways to use your skills
      for a greater good.
    </Body1>
    <Body1 fontColor="#a6bcff">Building Connections:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Community Organisations:</span> Join
      community organisations or clubs that focus on giving back. These groups
      can provide a platform for meeting like-minded individuals and working
      together on projects that benefit others.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Mentorship:</span> Offer mentorship to
      individuals who can benefit from your guidance, support, and experience.
      Your insights can be invaluable to someone just starting their journey or
      facing challenges you've navigated.
    </Body1>
    <Body1 fontColor="#a6bcff">Lifelong Learning and Growth:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Skills Development:</span> Engage in
      continuous learning to enhance your ability to contribute effectively.
      This could be through formal education, workshops, or online courses
      focused on community development, environmental sustainability, or
      leadership in non-profit organisations.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Reflective Practices:</span>{" "}
      Incorporate reflective practices like journaling to ponder your
      experiences, lessons learned, and the impact you've made. This can help
      clarify your goals and deepen your sense of purpose.
    </Body1>
    <Body1 fontColor="#a6bcff">Self-Care and Balance:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Self-Care Practices:</span> Balance
      your altruistic endeavours with activities that replenish your energy and
      well-being. Practices like mindfulness, yoga, or simply spending time in
      nature can help maintain your resilience.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Financial Stability:</span> While
      money may not be your primary motivator, ensuring financial stability is
      essential. Plan and manage your resources to support both your personal
      needs and your philanthropic activities without undue stress.
    </Body1>
    <Body1>
      As a{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Community Carer</span>
      , your innate desire to nurture and support others is a powerful force for
      good in the world. Your journey is about leveraging your empathy,
      foresight, and selflessness to create a positive ripple effect in your
      community and beyond. By combining your caring nature with strategic
      action, continuous learning, and self-care, you can sustain your ability
      to make meaningful contributions. Remember, the impact of kindness and
      care is immeasurable, and by dedicating yourself to these principles, you
      embody the change you wish to see in the world.
    </Body1>
  </>
);

const LifelongLearner = (
  <>
    <Heading2 fontFamily="valizas">
      You're a{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>
        Lifelong Learner
      </span>
    </Heading2>
    <Body1>
      You are the Lifelong Learner archetype, characterised by an insatiable
      curiosity and dedication to continuous personal and professional growth,
      this journey map is designed to foster your innate desire for knowledge,
      mastery, and meaningful impact. Lifelong Learners are motivated by the
      pursuit of expertise and the application of their skills and knowledge to
      navigate new challenges and make contributions to society. Your journey is
      about embracing every opportunity to learn, adapt, and apply your evolving
      wisdom, particularly as you transition to or navigate the later stages of
      life.
    </Body1>
    <Body1>
      You're <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Curious</span>
      <br></br>You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Inquisitive</span>
      <br></br>You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Persistent</span>
      <br></br>You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Open-minded</span>
      <br></br>You're{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Reflective</span>
    </Body1>
    <Body1 fontColor="#a6bcff">
      Continuous Education and Skill Development:
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Online Learning Platforms:</span> Take
      advantage of our online platform to explore new subjects or deepen
      existing expertise. The vast array of courses available can cater to your
      ever-evolving interests.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>
        Specialised Workshops and Certifications:
      </span>{" "}
      Attend workshops or pursue certifications in areas of interest. These can
      provide focused skill development and a sense of achievement as you master
      new domains.
    </Body1>
    <Body1 fontColor="#a6bcff">Application of Knowledge and Skills:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Consultancy or Advisory Roles:</span>{" "}
      Leverage your accumulated expertise by serving as a consultant or advisor
      in your field. This allows you to continue contributing your knowledge to
      the working world while enjoying a high degree of autonomy.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>
        Community Education Initiatives:
      </span>{" "}
      Share your skills and knowledge through community education programs,
      teaching, or mentoring. This could involve leading seminars, workshops, or
      online classes for community members interested in your areas of
      expertise.
    </Body1>
    <Body1 fontColor="#a6bcff">
      Networking and Lifelong Learning Communities:
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>
        Professional and Interest-Based Groups:
      </span>{" "}
      Join professional associations, clubs, or online communities that share
      your passion for learning and mastery. These networks can offer valuable
      resources, inspiration, and opportunities for collaboration.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Conferences and Symposiums:</span>{" "}
      Participate in industry conferences, symposiums, or academic forums. These
      gatherings are excellent for staying abreast of the latest developments in
      your field and networking with peers.
    </Body1>
    <Body1 fontColor="#a6bcff">Personal Projects and Research:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Independent Projects:</span> Embark on
      personal projects or research that aligns with your interests. This can be
      an avenue for practical application of new knowledge and a way to explore
      questions or challenges that fascinate you.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Writing and Publishing:</span>{" "}
      Consider writing articles, blogs, or books to share your insights and
      learnings with a wider audience. Publishing can solidify your status as an
      expert and contribute to your field's body of knowledge.
    </Body1>
    <Body1 fontColor="#a6bcff">Reflection and Intellectual Growth:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>
        Journaling and Thought Leadership:
      </span>{" "}
      Maintain a journal to reflect on your learnings and experiences. You can
      also engage in thought leadership by contributing to discussions, panels,
      or online forums on topics that interest you.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Mindfulness and Meditation:</span>{" "}
      Practice mindfulness or meditation to enhance your cognitive capabilities
      and maintain mental clarity. These practices can support deeper learning
      and creativity.
    </Body1>
    <Body1 fontColor="#a6bcff">Balance and Well-being:</Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>Work-Life Harmony:</span> Strive for a
      balance that allows time for personal interests, health, and family
      alongside your learning pursuits. This harmony is crucial for sustaining
      your passion for learning without burnout.
    </Body1>
    <Body1>
      <span style={{ fontWeight: "700" }}>
        Community and Social Connections:
      </span>{" "}
      While autonomy is valued, maintaining strong social connections is
      essential for well-being. Engage with friends, family, and learning
      communities to share experiences and insights.
    </Body1>
    <Body1>
      As a{" "}
      <span style={{ color: "var(--Lavender, #A6BCFF)" }}>
        Lifelong Learner
      </span>
      , your dedication to acquiring knowledge and applying it creatively is a
      testament to the belief that growth and development are lifelong
      processes. By embracing new learning opportunities, applying your
      expertise in meaningful ways, and connecting with like-minded individuals,
      you can continue to make a significant impact in your field and community.
      Your journey is not just about the accumulation of knowledge but about the
      joy of discovery and the fulfillment that comes from constant intellectual
      engagement. Keep pursuing your passions with an open mind and a commitment
      to excellence, and your later years will be as enriching and purposeful as
      ever.
    </Body1>
  </>
);
